import React, { memo, useContext, Fragment } from 'react';
import { Panel } from '@sdflc/ui';
import { MenuHeader, FoodMenu, DefaultLayout, PageLoading, BusinessHours, BusinessHoursOverlay } from '../components';
import { withProviders } from '../hoc';
import { SquareUpContext } from '../contexts';

const MenuPage = memo((props) => {
  const { menu, loadingData, firstTimeLoading, error } = useContext(SquareUpContext);

  if (firstTimeLoading) {
    return <PageLoading />;
  }

  return (
    <Fragment>
      <DefaultLayout title='Food & Beverages Menu'>
        <MenuHeader />
        <Panel padding={'20px'}>
          {error ? <span>{JSON.stringify(error.message)}</span> : null}
          {!error ? (
            <FoodMenu
              menu={[...menu['Food'], ...menu['Beverages']]}
              isLoading={loadingData}
              firstTimeLoading={firstTimeLoading}
              columnsQty={1}
              categoryWidth='20%'
              displayModifiers={false}
              showImages={false}
              noVariations={true}
            />
          ) : null}
        </Panel>
        <BusinessHoursOverlay />
      </DefaultLayout>
    </Fragment>
  );
});

MenuPage.displayName = 'MenuPage';

MenuPage.propTypes = {};

MenuPage.defaultProps = {};

export default withProviders(MenuPage);
